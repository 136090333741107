@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap);
@font-face {
  font-family: 'icomoon';
  src: url(/static/media/icomoon.b3173755.woff) format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-checkCircleOutlined-16:before {
  content: '\e9d0';
}
.icon-helpCenter-16:before {
  content: '\e9cf';
}
.icon-community-16:before {
  content: '\e9ce';
}
.icon-prizeBadge-16:before {
  content: '\e9a9';
}
.icon-closeCircleFilledLg-16:before {
  content: '\e9c6';
}
.icon-magicField-16:before {
  content: '\e9c5';
}
.icon-send-16:before {
  content: '\e9c4';
}
.icon-notif-pendding-16:before {
  content: '\e9c3';
}
.icon-spotify-16:before {
  content: '\e9c0';
}
.icon-beatsourceColorful-16:before {
  content: '\e9bd';
  color: #1a7ee3;
}
.icon-star-16:before {
  content: '\e9be';
}
.icon-beatsource-16:before {
  content: '\e9bc';
}
.icon-dnd-16:before {
  content: '\e999';
}
.icon-lightBulbOff-16:before {
  content: '\e9bb';
}
.icon-spinnerDisc-24:before {
  content: '\e9b7';
}
.icon-phoneAnswer-36:before {
  content: '\e9ca';
}
.icon-link-36:before {
  content: '\e9c9';
}
.icon-contactAnswer-36:before {
  content: '\e9c7';
}
.icon-sectionDefault-36:before {
  content: '\e9b1';
}
.icon-sectionFavorite-36:before {
  content: '\e9b2';
}
.icon-sectionHeadline-36:before {
  content: '\e9b3';
}
.icon-descriptionAnswer-36:before {
  content: '\e9a6';
}
.icon-checkboxAnswer-36:before {
  content: '\e9a7';
}
.icon-radiobuttonAnswer-36:before {
  content: '\e9a8';
}
.icon-locationAnswer-36:before {
  content: '\e9aa';
}
.icon-pdfAnswer-36:before {
  content: '\e9ab';
}
.icon-imageAnswer-36:before {
  content: '\e9ac';
}
.icon-textAnswer-36:before {
  content: '\e9ad';
}
.icon-gif-16:before {
  content: '\e9cd';
}
.icon-notifyMeBellFilled-16:before {
  content: '\e9cb';
}
.icon-notifyMeBellOutlined-16:before {
  content: '\e9cc';
}
.icon-embedVideo-16:before {
  content: '\e9c8';
}
.icon-heartFilled-16:before {
  content: '\e98a';
}
.icon-heartOutlined-16:before {
  content: '\e9b8';
}
.icon-mustPlayFilled-16:before {
  content: '\e9b9';
}
.icon-mustPlayOutlined-16:before {
  content: '\e9ba';
}
.icon-stripe-16:before {
  content: '\e9b5';
}
.icon-arrowUpDown-16:before {
  content: '\e9af';
}
.icon-add-file-16:before {
  content: '\e99f';
}
.icon-empty-file-16:before {
  content: '\e9a2';
}
.icon-image-16:before {
  content: '\e9a3';
}
.icon-pause-16:before {
  content: '\e9a1';
}
.icon-colorLine-16:before {
  content: '\e9a0';
}
.icon-share-arrow-16:before {
  content: '\e990';
}
.icon-spotifyColorful-16:before {
  content: '\e9a4';
  color: #1db954;
}
.icon-clone-16:before {
  content: '\e99c';
}
.icon-copy-16:before {
  content: '\e99b';
}
.icon-musicNoteDoubleOutlined-16:before {
  content: '\e99a';
}
.icon-userJoined-24:before {
  content: '\e9c1';
}
.icon-userLeft-24:before {
  content: '\e9c2';
}
.icon-sun-24:before {
  content: '\e9bf';
}
.icon-hostChangeHeadlineColor-24:before {
  content: '\e9b6';
}
.icon-hostAddSectionPhoto-24:before {
  content: '\e9b4';
}
.icon-academy-24:before {
  content: '\e9b0';
}
.icon-youtube-16:before {
  content: '\e9ae';
}
.icon-notifications-bell-24:before {
  content: '\e9a5';
}
.icon-helpCenter-24:before {
  content: '\e99d';
}
.icon-lightBulb-24:before {
  content: '\e99e';
}
.icon-playFilled-24:before {
  content: '\e98c';
}
.icon-locked-24:before {
  content: '\e991';
}
.icon-email-24:before {
  content: '\e98f';
}
.icon-creditCard-24:before {
  content: '\e982';
}
.icon-site-24:before {
  content: '\e980';
}
.icon-mobile-16:before {
  content: '\e97f';
}
.icon-creditCardChecked-24:before {
  content: '\e981';
}
.icon-sandClockEnd-24:before {
  content: '\e983';
}
.icon-sandClockStart-24:before {
  content: '\e984';
}
.icon-djArrivalTime-24:before {
  content: '\e985';
}
.icon-searchOnScreen-24:before {
  content: '\e986';
}
.icon-timelineLayout-24:before {
  content: '\e987';
}
.icon-notes-24:before {
  content: '\e988';
}
.icon-infoRotatedOutlined-24:before {
  content: '\e989';
}
.icon-clock-16:before {
  content: '\e98b';
}
.icon-location-24:before {
  content: '\e98d';
}
.icon-phone-24:before {
  content: '\e98e';
}
.icon-layoutLandscape-16:before {
  content: '\e992';
}
.icon-layoutPortrait-16:before {
  content: '\e993';
}
.icon-editPencil-24:before {
  content: '\e994';
}
.icon-copyText-16:before {
  content: '\e995';
}
.icon-delete-16:before {
  content: '\e996';
}
.icon-userV2-16:before {
  content: '\e997';
}
.icon-arrowLeft-24:before {
  content: '\e998';
}
.icon-camera-24:before {
  content: '\e97e';
}
.icon-noImage-36:before {
  content: '\e900';
}
.icon-noImage-160:before {
  content: '\e97d';
}
.icon-arrowDown-24:before {
  content: '\e901';
}
.icon-arrowRight-24:before {
  content: '\e902';
}
.icon-arrowUp-24:before {
  content: '\e903';
}
.icon-bell-24:before {
  content: '\e904';
}
.icon-birthday-24:before {
  content: '\e905';
}
.icon-calendar-24:before {
  content: '\e906';
}
.icon-caretLeft-24:before {
  content: '\e907';
}
.icon-caretRight-24:before {
  content: '\e908';
}
.icon-changePassword-24:before {
  content: '\e909';
}
.icon-checkCircleOutlined-24:before {
  content: '\e90a';
}
.icon-clock-24:before {
  content: '\e90b';
}
.icon-clone-24:before {
  content: '\e90c';
}
.icon-copyText-24:before {
  content: '\e90d';
}
.icon-corporate-24:before {
  content: '\e90e';
}
.icon-crown-24:before {
  content: '\e90f';
}
.icon-delete-24:before {
  content: '\e910';
}
.icon-download-24:before {
  content: '\e911';
}
.icon-events-24:before {
  content: '\e912';
}
.icon-guestsInviteGuests-24:before {
  content: '\e913';
}
.icon-headphones-24:before {
  content: '\e914';
}
.icon-headphonesWithMic-24:before {
  content: '\e915';
}
.icon-heartFilled-24:before {
  content: '\e916';
}
.icon-hostsAddSections-24:before {
  content: '\e917';
}
.icon-hostsInviteGuests-24:before {
  content: '\e918';
}
.icon-hostsReorderSections-24:before {
  content: '\e919';
}
.icon-location-241:before {
  content: '\e91a';
}
.icon-lockedCalendar-24:before {
  content: '\e91b';
}
.icon-logOut-24:before {
  content: '\e91c';
}
.icon-m3u-24:before {
  content: '\e91d';
}
.icon-mitzvah-24:before {
  content: '\e91e';
}
.icon-musicNoteDoubleOutlined-24:before {
  content: '\e91f';
}
.icon-musicNoteSingleOutlined-24:before {
  content: '\e920';
}
.icon-notVisible-24:before {
  content: '\e921';
}
.icon-party-24:before {
  content: '\e922';
}
.icon-plus-24:before {
  content: '\e923';
}
.icon-questionOutlined-24:before {
  content: '\e924';
}
.icon-quinceanera-24:before {
  content: '\e925';
}
.icon-refresh-24:before {
  content: '\e926';
}
.icon-scanner-24:before {
  content: '\e927';
}
.icon-schoolDance-24:before {
  content: '\e928';
}
.icon-send-24:before {
  content: '\e929';
}
.icon-settings-24:before {
  content: '\e92a';
}
.icon-sweet16-24:before {
  content: '\e92b';
}
.icon-templates-24:before {
  content: '\e92c';
}
.icon-termsConditions-24:before {
  content: '\e92d';
}
.icon-user-24:before {
  content: '\e92e';
}
.icon-visible-24:before {
  content: '\e92f';
}
.icon-wedding-24:before {
  content: '\e930';
}
.icon-appleMusic-16:before {
  content: '\e931';
}
.icon-arrowDown-16:before {
  content: '\e932';
}
.icon-arrowLeft-16:before {
  content: '\e933';
}
.icon-arrowLeftRight-16:before {
  content: '\e934';
}
.icon-arrowRight-16:before {
  content: '\e935';
}
.icon-arrowUp-16:before {
  content: '\e936';
}
.icon-birthday-16:before {
  content: '\e937';
}
.icon-bold-16:before {
  content: '\e938';
}
.icon-bulletList-16:before {
  content: '\e939';
}
.icon-caretDown-16:before {
  content: '\e93a';
}
.icon-caretDownFilled-16:before {
  content: '\e93b';
}
.icon-caretLeft-16:before {
  content: '\e93c';
}
.icon-caretRight-16:before {
  content: '\e93d';
}
.icon-caretUp-16:before {
  content: '\e93e';
}
.icon-caretUpFilled-16:before {
  content: '\e93f';
}
.icon-checkedCalendar-16:before {
  content: '\e940';
}
.icon-checkedCircleFilled-16:before {
  content: '\e941';
}
.icon-checkedFilled-16:before {
  content: '\e942';
}
.icon-checkMark-16:before {
  content: '\e943';
}
.icon-clearFormatting-16:before {
  content: '\e944';
}
.icon-close-16:before {
  content: '\e945';
}
.icon-closeCircleFilled-16:before {
  content: '\e946';
}
.icon-colapse-16:before {
  content: '\e947';
}
.icon-comment-16:before {
  content: '\e948';
}
.icon-corporate-16:before {
  content: '\e949';
}
.icon-crown-16:before {
  content: '\e94a';
}
.icon-dj-16:before {
  content: '\e94b';
}
.icon-editPencil-16:before {
  content: '\e94c';
}
.icon-email-16:before {
  content: '\e94d';
}
.icon-emptyNotes-16:before {
  content: '\e94e';
}
.icon-expand-16:before {
  content: '\e94f';
}
.icon-flagFilled-16:before {
  content: '\e950';
}
.icon-flagOutlined-16:before {
  content: '\e951';
}
.icon-folder-16:before {
  content: '\e952';
}
.icon-host-16:before {
  content: '\e953';
}
.icon-indeterminateFilled-16:before {
  content: '\e954';
}
.icon-indeterminateOutlined-16:before {
  content: '\e955';
}
.icon-infoCircleFilled-16:before {
  content: '\e956';
}
.icon-infoCircleOutlined-16:before {
  content: '\e957';
}
.icon-infoRotatedOutlined-16:before {
  content: '\e958';
}
.icon-italic-16:before {
  content: '\e959';
}
.icon-link-16:before {
  content: '\e95a';
}
.icon-location-16:before {
  content: '\e95b';
}
.icon-locked-16:before {
  content: '\e95c';
}
.icon-m3u-16:before {
  content: '\e95d';
}
.icon-mitzvah-16:before {
  content: '\e95e';
}
.icon-notes-16:before {
  content: '\e95f';
}
.icon-notVisible-16:before {
  content: '\e960';
}
.icon-numberList-16:before {
  content: '\e961';
}
.icon-party-16:before {
  content: '\e962';
}
.icon-personalComputer-16:before {
  content: '\e963';
}
.icon-phone-16:before {
  content: '\e964';
}
.icon-play-16:before {
  content: '\e965';
}
.icon-plus-16:before {
  content: '\e966';
}
.icon-public-16:before {
  content: '\e967';
}
.icon-questionCircleFilled-16:before {
  content: '\e968';
}
.icon-questionCircleOutlined-16:before {
  content: '\e969';
}
.icon-quinceanera-16:before {
  content: '\e96a';
}
.icon-radioButtonOff-16:before {
  content: '\e96b';
}
.icon-radioButtonOn-16:before {
  content: '\e96c';
}
.icon-redo-16:before {
  content: '\e96d';
}
.icon-schoolDance-16:before {
  content: '\e96e';
}
.icon-search-16:before {
  content: '\e96f';
}
.icon-settings-16:before {
  content: '\e970';
}
.icon-site-16:before {
  content: '\e971';
}
.icon-stop-16:before {
  content: '\e972';
}
.icon-sweet16-16:before {
  content: '\e973';
}
.icon-text-16:before {
  content: '\e974';
}
.icon-threeDots-16:before {
  content: '\e975';
}
.icon-threeDotsCircle-16:before {
  content: '\e976';
}
.icon-uncheckedOutlined-16:before {
  content: '\e977';
}
.icon-underline-16:before {
  content: '\e978';
}
.icon-undo-16:before {
  content: '\e979';
}
.icon-visible-16:before {
  content: '\e97a';
}
.icon-wedding-16:before {
  content: '\e97b';
}
.icon-spinner:before {
  content: '\e97c';
}

:root {
  --modal-sm-h: 170px;
  --modal-sm-w: 400px;
  --modal-md-h: 370px;
  --modal-lg-h: 310px;
  --modal-lg-w: 680px;
  --modal-xlg-h: 370px;
  --modal-xxlg-h: 370px;
  --modal-xxlg-w: 750px;

  --timeline-sections-w: 288px;
  --timeline-conten-gap: 30px;

  --shadows-0: none;
  --shadows-1: 0px 0.5px 3px 0px rgba(0, 0, 0, 0.45);
  --shadows-2: inset 0px 1px 6px rgba(0, 0, 0, 0.3);
  --shadows-3: 0px 0.5px 3px 0px rgba(0, 0, 0, 0.45);
  --shadows-4: 0px 0.5px 2px rgba(0, 0, 0, 0.35);
  --shadows-5: 0 0.5px 4px 0 rgba(0, 0, 0, 0.1);
  --shadows-6: 0px 2px 6px rgba(0, 0, 0, 0.1);
  --shadows-7: 0px 1px 6px rgba(0, 0, 0, 0.3);
  --shadows-8: 0px 2px 6px rgba(0, 0, 0, 0.3);
  --shadows-9: inset 0px 0px 4px 0px #00000040;
  --shadows-10: 0px 0.5px 8px 0px #0000001a;

  --clr-mountainMeadow: #1db954;
  --clr-wildSand: #f4f4f4;
  --clr-white: #ffffff;
  --clr-black: #000000;
  --clr-emperor: #505050;
  --clr-balticSea: #2a292d;
  --clr-balticSeaDark: #19181f;
  --clr-shipGray: #3e3b43;
  --clr-dustyGray: #999999;
  --clr-silver: #cacaca;
  --clr-gray: #808080;
  --clr-shalimar: #fffbb9;
  --clr-lisbonBrown: #3f3d1e;
  --clr-pomegranate: #f03a2e;
  --clr-carnation: #f36158;
  --clr-yellow: #fcf403;
  --clr-oldGold: #d4af37;
  --clr-malachite: #1ed760;
  --clr-malachiteLight: #1bd317;
  --clr-malachiteLighty: #bbf2ba;
  --clr-fetaLight: #effcef;
  --clr-frenchPass: #badcfd;
  --clr-corn: #e3da02;
  --clr-california: #f39604;
  --clr-kashmirBlue: #475993;
  --clr-texasRose: #ffc250;
  --clr-sunsetOrange: #fd5341;
  --clr-mediumRedViolet: #cb39a4;
  --clr-scarpaFlow: #56535e;
  --clr-feta: #e9fbe8;

  --warning-main: var(--clr-oldGold);
  --error-main: var(--clr-pomegranate);
  --error-light: var(--clr-carnation);
  --success-main: var(--clr-malachiteLight);
  --success-light: var(--clr-malachiteLighty);
  --info-main: var(--clr-yellow);
  --action-disabled: var(--clr-frenchPass);
  --progress-low: var(--clr-carnation);
  --progress-middle: var(--clr-yellow);
  --progress-full: var(--clr-malachiteLight);
}

body[theme='light'] {
  --clr-toryBlue: hsl(var(--light-hue) 79% 32%);
  --clr-dodgerBlueLight: hsl(var(--light-hue) 100% 70%);
  --clr-selago: hsl(var(--light-hue) 83% 98%);
  --clr-tropicalBlue: hsl(var(--light-hue) 79% 85%);
  --clr-hawkesBlue: hsl(var(--light-hue) 83% 95%);
  --action-hover: var(--clr-toryBlue);

  /* GENERAL */
  --selection-hover: var(--clr-hawkesBlue);
  --selection-iconsHover: var(--clr-tropicalBlue);
  --selection-menuHover: var(--light-color);
  --selection-filterHover: var(--clr-hawkesBlue);
  --menuItemSelectedBg: var(--clr-hawkesBlue);
  --menuItemHoverBg: var(--clr-toryBlue);
  --whiteToBalticSeaDark: var(--clr-white);
  --whiteToBlack: var(--clr-white);
  --sectionHover: var(--clr-hawkesBlue);
  --whiteToEmperor: var(--clr-white);
  --primaryLightToBalticSeaDark: var(--primary-light);
  --primaryLightToBalticSea: var(--primary-light);
  --whiteToShipGray: var(--clr-white);
  --successLightToShipGray: var(--success-light);
  --primaryLightToShipGray: var(--primary-light);
  --primaryLightToGray: var(--primary-light);
  --transparentToWhite: transparent;
  --balticSeaToWhite: var(--clr-balticSea);
  --buttonActiveToWhite: var(--clr-toryBlue);
  --selagoToScarpaFlow: var(--clr-selago);
  --primaryLightToScarpaFlow: var(--primary-light);
  --fetaToBalticSeaDark: var(--clr-feta);
  --dustGrayToSilver: var(--clr-dustyGray);
  --primaryLightToPrimaryMain: var(--primary-light);
  --primaryMainToPrimaryLight: var(--primary-main);
  --vibo: #1a7ee3;
  --must-play-main: var(--clr-shalimar);
  --toryBlueToPrimaryLight: var(--clr-toryBlue);
  --emperorToWhite: var(--clr-emperor);
  --primaryLightToTropicalBlue: var(--primary-light);
  --fetaLightToShipGray: var(--clr-fetaLight);
  --dustyGrayToEmperor: var(--clr-dustyGray);

  /* PRIMARY */
  --primary-main: var(--light-color);
  --primary-light: var(--clr-tropicalBlue);
  --primary-lighty: var(--clr-hawkesBlue);
  --primary-contrastText: var(--clr-white);

  /* TEXT */
  --text-primary: var(--clr-black);
  --text-secondary: var(--clr-emperor);
  --text-hint: var(--clr-dustyGray);
  --text-disabled: var(--clr-silver);

  /* BACKGROUND */
  --background-default: var(--clr-selago);
  --background-paper: var(--clr-white);
  --background-photo: var(--clr-tropicalBlue);
  --background-dropDown: var(--clr-white);
  --background-input: var(--clr-selago);

  /* BUTTON */
  --button-disabled: var(--clr-tropicalBlue);
  --button-active: var(--clr-toryBlue);

  /* OVERLAY */
  --overlay-default: 'rgba(255, 255, 255, 0.7)';
  --overlay-skeleton: 'linear-gradient(90deg, #ddd 0%, #eee 100%)';
}

body[theme='dark'] {
  --clr-toryBlue: hsl(var(--dark-hue) 79% 32%);
  --clr-dodgerBlueLight: hsl(var(--dark-hue) 100% 70%);
  --clr-selago: hsl(var(--dark-hue) 83% 98%);
  --clr-tropicalBlue: hsl(var(--dark-hue) 79% 85%);
  --clr-hawkesBlue: hsl(var(--dark-hue) 83% 95%);
  --action-hover: var(--clr-toryBlue);

  /* GENERAL */
  --selection-hover: var(--clr-shipGray);
  --selection-iconsHover: var(--clr-balticSeaDark);
  --selection-menuHover: var(--clr-dodgerBlueLight);
  --selection-filterHover: var(--clr-emperor);
  --menuItemSelectedBg: var(--clr-toryBlue);
  --menuItemHoverBg: var(--clr-dodgerBlueLight);
  --whiteToBalticSeaDark: var(--clr-balticSeaDark);
  --whiteToBlack: var(--clr-black);
  --sectionHover: var(--clr-shipGray);
  --whiteToEmperor: var(--clr-emperor);
  --primaryLightToBalticSeaDark: var(--clr-balticSeaDark);
  --primaryLightToBalticSea: var(--clr-balticSea);
  --whiteToShipGray: var(--clr-shipGray);
  --successLightToShipGray: var(--clr-shipGray);
  --primaryLightToShipGray: var(--clr-shipGray);
  --primaryLightToGray: var(--clr-gray);
  --transparentToWhite: var(--clr-white);
  --balticSeaToWhite: var(--clr-white);
  --buttonActiveToWhite: var(--clr-white);
  --selagoToScarpaFlow: var(--clr-scarpaFlow);
  --primaryLightToScarpaFlow: var(--clr-scarpaFlow);
  --fetaToBalticSeaDark: var(--clr-balticSeaDark);
  --dustGrayToSilver: var(--clr-silver);
  --primaryLightToPrimaryMain: var(--primary-main);
  --primaryMainToPrimaryLight: var(--primary-light);
  --vibo: #2d8ff1;
  --must-play-main: var(--clr-lisbonBrown);
  --toryBlueToPrimaryLight: var(--primary-light);
  --emperorToWhite: var(--clr-white);
  --primaryLightToTropicalBlue: var(--clr-tropicalBlue);
  --fetaLightToShipGray: var(--clr-shipGray);
  --dustyGrayToEmperor: var(--clr-emperor);

  /* PRIMARY */
  --primary-main: var(--dark-color);
  --primary-light: var(--clr-dodgerBlueLight);
  --primary-lighty: var(--clr-shipGray);
  --primary-contrastText: var(--clr-white);

  /* TEXT */
  --text-primary: var(--clr-white);
  --text-secondary: var(--clr-white);
  --text-hint: var(--clr-gray);
  --text-disabled: var(--clr-silver);

  /* BACKGROUND */
  --background-default: var(--clr-balticSeaDark);
  --background-paper: var(--clr-balticSea);
  --background-photo: var(--clr-shipGray);
  --background-dropDown: var(--clr-balticSeaDark);
  --background-input: var(--clr-shipGray);

  /* BUTTON */
  --button-disabled: var(--clr-toryBlue);
  --button-active: var(--clr-dodgerBlueLight);

  /* OVERLAY */
  --overlay-default: 'rgba(42, 41, 45, 0.7)';
  --overlay-skeleton: 'linear-gradient(90deg, rgba(42,41,45,1) 0%, rgba(62,59,67,1) 100%)';
}

@media screen and (min-width: 1441px) {
  :root {
    --modal-sm-h: 220px;
    --modal-sm-w: 560px;
    --modal-md-h: 524px;
    --modal-lg-h: 364px;
    --modal-lg-w: 800px;
    --modal-xlg-h: 564px;
    --modal-xxlg-h: 600px;
    --modal-xxlg-w: 1000px;

    --timeline-sections-w: 384px;
    --timeline-conten-gap: 56px;
  }
}



